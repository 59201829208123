<template>
  <div class="patient page">
    <NavMenu defaultActive="/case/list">
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/icon_5.png" alt="ico-title" />
              <span>{{$t('case.detailTitle')}}</span>
            </div>
            <el-button class="edit" type="primary" @click="comeback">{{$t('common.return')}}</el-button>
           
          </h2>
           <div class="details">
               <h3 class="label">{{$t('case.basic')}}</h3>
               <div class="info">
                   <ul class="column">
                       <li class="row">
                            <span class="key">{{$t('case.name')}}： </span>
                            <span class="val">{{info.patient_name}}</span>
                       </li>
                        <li class="row">
                            <span class="key">{{$t('case.patient')}}： </span>
                            <span class="val">{{info.patient_number}}</span>
                       </li>
                        <li class="row">
                            <span class="key">{{$t('case.dealer')}}： </span>
                            <span class="val">{{info.partent}}</span>
                       </li>
                   </ul>
                   <ul class="column">
                       <li class="row">
                            <span class="key">{{$t('case.serial')}}： </span>
                            <span class="val">{{info.case_number}}</span>
                       </li>
                        <li class="row">
                            <span class="key">{{$t('case.hospital')}}： </span>
                            <span class="val">{{info.hospital}}</span>
                       </li>
                        <li class="row">
                             <span class="key">{{$t('case.side')}}： </span>
                             <span class="val">{{info.slide_text}}</span>
                        </li>
                   </ul>
                   <ul class="column">
                        <li class="row">
                            <span class="key">{{$t('case.information')}}： </span>
                            <span class="val">{{info.information}}</span>
                       </li>
                        
                        
					   <li class="row">
					        <span class="key">{{$t('case.doctor')}}： </span>
					        <span class="val">{{info.doctor}}</span>
					   </li>
					   <li class="row">
					        <span class="key">{{$t('case.time')}}： </span>
					        <span class="val">{{info.add_time_text}}</span>
					   </li>
                   </ul>
               </div>
               <h3 class="label">{{$t('case.note')}}</h3>
               <div class="info">
                   {{info.note}}
               </div>
           </div>
           
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
  data() {
    return {
		info:""
    };
  },
  mounted(){
	 if(this.$route.query.id){
		 this.getDetail(this.$route.query.id)
	 }else{
		 this.$message.error(this.$t('common.errMsg2'));
		 setTimeout(res=>{
			this.$router.go(-1)
		 },500)
	 }
  },
  methods: {
    comeback(){
      this.$router.push('/case/list')
    },
		getDetail(id){
			this.$api.caseDetail(id).then(res=>{
				this.info = res.data;
			})
		
		}

  },
};
</script>
<style scoped>
.right-content {
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}


.btn {
  background: #fff;
}
.details{
    padding: 20px 12px;
    padding-top: 40px;
}
.details .label{
    font-size: 14px;
    font-weight: bold;
    color: #686868;
    position: relative;
    padding-left: 15px;
}
.details .label::before{
    content: '';
    width: 5px;
    height: 13px;
    background: #364284;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-52%);
}
.info{
    display: flex;
    margin: 40px 0;
    padding-left: 28px;
}
.info .column{
    width: 28%;
}
.info .row{
    font-size: 13px;
    font-weight: 400;
    color: #686868;
    margin-bottom: 20px;
}
.info .row .val{
    font-size: 13px;
    font-family: FZZhunYuan-M02S;
    font-weight: bold;
    color: #383838;
}
.right-content .foot {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
}
.btn-comeback{
  font-size: 14px;
  cursor: pointer;
}
</style>
